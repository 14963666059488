import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  blueglass,
  cloud,
  dusk,
  heather,
  midnight,
  white,
} from 'src/constants/colors';
import { Close } from '@mui/icons-material';
import {
  FusionExecutionLogDataType,
  FusionExecutionStatus,
  useFusionExecutionLogsQuery,
} from 'src/services/gql/generated';
import { red } from '@mui/material/colors';
import DownArrow from '../../assets/icn-chevron-down.svg';
import Loader from '@components/Loader';

type Props = {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  fusionSourceId: string;
};

const isGetFusionSourceExecutionDetailsResult = (
  log: any,
): log is {
  cursorId: string;
  executionTimestamp: string;
  fusionSourceId: string;
  logData: string;
  logDataType: FusionExecutionLogDataType;
  status: FusionExecutionStatus;
} => {
  return (log as { cursorId: string }).cursorId !== undefined;
};

const LogsModal: React.FC<Props> = ({ open, setIsOpen, fusionSourceId }) => {
  const { data, loading } = useFusionExecutionLogsQuery({
    variables: {
      getFusionExecutionLogsInput: { fusionSourceOrModuleId: fusionSourceId },
    },
  });
  const executionDataLogs = data?.fusionExecutionLogs?.nodes;
  const [logsData, setLogsData] = useState(executionDataLogs);
  const filterOptions = ['VIEW ALL', 'FAILED', 'SUCCEEDED'];
  const [viewAll, setToggleViewAll] = useState({
    isVisible: false,
    isActive: 'VIEW ALL',
  });

  useEffect(() => {
    setLogsData(executionDataLogs);
  }, [executionDataLogs]);

  useEffect(() => {
    if (viewAll?.isActive !== 'VIEW ALL') {
      const filteredData = executionDataLogs?.filter((data) => {
        return isGetFusionSourceExecutionDetailsResult(data)
          ? data.status === viewAll?.isActive
          : null;
      });
      setLogsData(filteredData);
    } else {
      setLogsData(executionDataLogs);
    }
  }, [viewAll?.isActive]);

  const isEmptyLogs =
    data?.fusionExecutionLogs?.nodes?.length === 0 ? true : false;

  return (
    <Dialog
      fullWidth={false}
      maxWidth="lg"
      onClose={() => {
        setIsOpen(false);
      }}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: white,
          borderRadius: '4px',
          width: '565px',
          minHeight: '300px',
          maxHeight: '550px',
        },
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Stack
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="center"
          sx={{
            backgroundColor: white,
            fontFamily: 'Rota',
            overflow: 'auto',
          }}
        >
          <Grid
            container
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: '70px',
              boxShadow: !isEmptyLogs
                ? '0px 4px 20px 0px rgba(0, 0, 0, 0.05);'
                : 'none',
            }}
          >
            <DialogTitle
              sx={{
                lineHeight: '22px',
                p: 0,
                textAlign: 'left',
                width: '100%',
                flex: '1 1 auto',
                paddingLeft: '75px',
                display: 'flex',
                gap: '8px',
              }}
            >
              <Typography sx={{ lineHeight: '1.5' }}>
                {!isEmptyLogs ? 'Parser Logs' : null}
              </Typography>

              <Stack
                flexDirection={'row'}
                sx={{
                  width: 'auto',
                  gap: '4px',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setToggleViewAll((pre) => ({
                    ...pre,
                    isVisible: !pre?.isVisible,
                  }));
                }}
              >
                <Typography
                  sx={{
                    color: blueglass,
                    fontFamily: 'RotaBold',
                    fontSize: '14px',
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {viewAll?.isActive?.toLowerCase()}
                </Typography>
                <Typography
                  component={'span'}
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: heather,
                    marginBottom: '6px',
                  }}
                >
                  <img
                    src={DownArrow}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    alt=""
                  />
                </Typography>

                <Box
                  sx={{
                    width: '143px',
                    height: '133px',
                    zIndex: 3,
                    position: 'absolute',
                    display: viewAll?.isVisible ? 'block' : 'none',
                    top: '30px',
                    left: '-25px',
                    backgroundColor: white,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <Stack
                    sx={{
                      padding: '8px 6px',
                    }}
                  >
                    {filterOptions?.map((option, index) => {
                      return (
                        <Typography
                          key={index}
                          sx={{
                            height: '40px',
                            color: midnight,
                            fontSize: '16px',
                            display: 'flex',
                            fontWeight: 700,
                            textTransform: 'capitalize',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '2px 6px',
                            '&:hover': {
                              backgroundColor: cloud,
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setToggleViewAll((pre) => ({
                              ...pre,
                              isActive: option,
                            }));
                          }}
                        >
                          {option.toLowerCase()}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Box>
              </Stack>
            </DialogTitle>

            <Box
              sx={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
                position: 'absolute',
                right: '24px',
              }}
            >
              <Close
                sx={{ color: dusk }}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </Box>
          </Grid>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              // minHeight: '250px',
              maxHeight: '550px',
              overflowY: 'scroll',
              width: '100%',
              paddingRight: '6px',
            }}
          >
            {isEmptyLogs ? (
              <DialogContent
                sx={{
                  maxHeight: '550px',
                  width: '550px',
                  background: red,
                  padding: 0,
                }}
              >
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  gap="16px"
                  sx={{ height: '160px', marginTop: '6%' }}
                >
                  <Typography
                    component="span"
                    sx={{ fontSize: '18px', color: midnight }}
                  >
                    Parser Logs
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: '18px', color: dusk }}
                  >
                    This parser does not have any logs yet
                  </Typography>
                </Stack>
              </DialogContent>
            ) : (
              <DialogContent
                sx={{
                  minHeight: '250px',
                  maxHeight: '550px',
                  width: '550px',
                  background: red,
                  pl: '60px',
                }}
              >
                <List>
                  {logsData?.length === 0 ? (
                    <Stack
                      flexDirection="column"
                      alignItems="center"
                      gap="16px"
                      sx={{ height: '160px', marginTop: '6%' }}
                    >
                      <Typography
                        component="span"
                        sx={{ fontSize: '18px', color: midnight }}
                      >
                        Parser Logs
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ fontSize: '18px', color: dusk }}
                      >
                        This parser does not have any failed logs
                      </Typography>
                    </Stack>
                  ) : (
                    logsData?.map((log: any) =>
                      isGetFusionSourceExecutionDetailsResult(log) ? (
                        <ListItem key={log.cursorId}>
                          <ListItemText
                            primary={`Cursor ID: ${log.cursorId}`}
                            secondary={
                              <>
                                <Typography component="span">
                                  Execution Timestamp:{' '}
                                  {new Date(
                                    log.executionTimestamp,
                                  ).toLocaleString()}
                                </Typography>
                                <br />
                                <Typography component="span">
                                  Status: {log.status}
                                </Typography>
                                <br />
                                <Typography component="span">
                                  Log Data:{' '}
                                  <a
                                    href={log.logData}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: blueglass,
                                      fontWeight: 800,
                                      textDecoration: 'none',
                                    }}
                                  >
                                    View Log
                                  </a>
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      ) : null,
                    )
                  )}
                </List>
              </DialogContent>
            )}
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};

export default LogsModal;
