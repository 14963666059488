import { gql } from '@apollo/client';

export const GenerateEmailConfirmationTokenMutation = gql`
  mutation generateEmailConfirmationToken($email: String) {
    generateEmailConfirmationToken(input: { email: $email }) {
      boolean
    }
  }
`;

export const ConfirmEmail = gql`
  mutation confirmEmail($email: String!, $token: String!) {
    confirmEmail(email: $email, token: $token)
  }
`;
