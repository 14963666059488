import { FetchResult, useMutation, useQuery } from '@apollo/client';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ROLES } from './constants';
import { AddUserMutation } from 'src/services/gql/mutations/AddUserMutation.gql';
import {
  AddUserMutation as AddUserMutationResponse,
  RemoveUserRolesMutation as RemoveUserRolesMutationResponse,
  UserProfileQuery as UserProfileQueryResponse,
  AddBotMutation as AddBotMutationResponse,
  UpdateBotMutation as UpdateBotMutationResponse,
  RemoveBotMutation as RemoveBotMutationResponse,
  BotsByTypeQuery as BotsByTypeResponse,
  BotType,
  useAddBotMutation,
  useRemoveBotMutation,
  useAllEmailSendersQuery,
  useAddEmailSenderDomainMutation,
  AddEmailSenderDomainMutation as AddEmailSenderDomainMutationResponse,
  useRemoveEmailSenderDomainMutation,
  RemoveEmailSenderDomainMutation as RemoveEmailSenderDomainMutationResponse,
  useUpdateDefaultEmailSenderDomainMutation,
  UpdateDefaultEmailSenderDomainMutation as UpdateDefaultEmailSenderDomainResponse,
  useUpdateDefaultEmailSenderDisplayNameMutation,
  UpdateDefaultEmailSenderDisplayNameMutation as UpdateDefaultEmailSenderDisplayNameResponse,
  useUpdateDefaultEmailSenderNameMutation,
  UpdateDefaultEmailSenderNameMutation as UpdateDefaultEmailSenderNameResponse,
  useGetEmailSenderDefaultsQuery,
  useCreateDiscordBotConfigurationMutation,
  CreateDiscordBotConfigurationMutation as CreateDiscordBotConfigurationMutationResponse,
  UpdateDiscordBotConfigurationMutation as UpdateDiscordBotConfigurationMutationResponse,
  DeleteDiscordBotConfigurationMutation as DeleteDiscordBotConfigurationMutationResponse,
  useTenantDiscordBotConfigurationQuery,
  Totp2FaRequiredForEmailLoginsQuery,
  Is2faQuery,
  useUpdateBotMutation,
  useUpdateDiscordBotConfigurationMutation,
  useDeleteDiscordBotConfigurationMutation,
  TenantDiscordBotConfigurationDocument,
} from 'src/services/gql/generated';
import { RemoveUserRoles } from 'src/services/gql/mutations/RemoveUserRoleMutation.gql';
import {
  TenantUsersQuery,
  TenantUsersResponse,
} from 'src/services/gql/queries/TenantUsersQuery.gql';
import { UserProfileQuery } from 'src/services/gql/queries/UserProfileQuery.gql';
import { BotsByTypeQuery } from 'src/services/gql/queries/BotsByTypeQuery.gql';
import { AllEmailSendersQuery } from 'src/services/gql/queries/AllEmailSendersQuery.gql';
import { EmailSenderDefaultsQuery } from 'src/services/gql/queries/GetEmailSenderDefaultsQuery.gql';
import { totp2FARequiredForEmailLoginsQuery } from 'src/services/gql/queries/totp2FARequiredForEmailLoginsQuery.gql';
import { is2faQuery } from 'src/services/gql/queries/Is2FaQuery.gql';

export type User = {
  email: string;
  roles: string[];
};

export type Bot = {
  id: string;
};

export type EmailDomainSender = {
  id: string | undefined;
};

type AccountSettingsDataContextState = Readonly<{
  showLimitAlert: boolean;
  setShowLimitAlert: (value: boolean) => void;
  createOrUpdateUser: (
    email: string,
    roles: string[],
  ) => Promise<FetchResult<AddUserMutationResponse>>;
  createUserLoading: boolean;
  removeUserLoading: boolean;
  removeUser: (
    email: string,
    roles: string[],
  ) => Promise<FetchResult<RemoveUserRolesMutationResponse>>;
  createBot: (
    credentials: string,
    botType: string,
    name: string,
  ) => Promise<FetchResult<AddBotMutationResponse>>;
  updateBot: (
    id: string,
    name: string,
    credentials?: string,
  ) => Promise<FetchResult<UpdateBotMutationResponse>>;
  removeBot: (id: string) => Promise<FetchResult<RemoveBotMutationResponse>>;
  createOrUpdateDomain: (
    domainName: string,
  ) => Promise<FetchResult<AddEmailSenderDomainMutationResponse>>;
  createDomainLoading: boolean;
  updateDefaultDomain: (
    id: string,
  ) => Promise<FetchResult<RemoveEmailSenderDomainMutationResponse>>;
  removeDomain: (
    id: string,
  ) => Promise<FetchResult<UpdateDefaultEmailSenderDomainResponse>>;
  updateDefaultDisplayName: (
    defaultDisplayName: string,
  ) => Promise<FetchResult<UpdateDefaultEmailSenderDisplayNameResponse>>;
  updateDefaultName: (
    defaultName: string,
  ) => Promise<FetchResult<UpdateDefaultEmailSenderNameResponse>>;
  createDiscordBotConfiguration: (
    discordClientId: string,
    discordClientSecret: string,
    discordGuildId: string,
    discordServerInviteLink: string,
    redirectUrl: string,
    name: string,
  ) => Promise<FetchResult<CreateDiscordBotConfigurationMutationResponse>>;
  updateDiscordBotConfiguration: (
    discordGuildId: string,
    discordServerInviteLink: string,
    name: string,
    redirectUrl: string,
  ) => Promise<FetchResult<UpdateDiscordBotConfigurationMutationResponse>>;
  removeDiscordBotConfiguration: () => Promise<
    FetchResult<DeleteDiscordBotConfigurationMutationResponse>
  >;
}>;

const AccountSettingsDataContext =
  createContext<AccountSettingsDataContextState>({
    createOrUpdateUser: () => Promise.reject('Uninitialized'),
    createUserLoading: false,
    removeUserLoading: false,
    removeUser: () => Promise.reject('Uninitialized'),
    createBot: () => Promise.reject('Uninitialized'),
    updateBot: () => Promise.reject('Uninitialized'),
    removeBot: () => Promise.reject('Uninitialized'),
    createOrUpdateDomain: () => Promise.reject('Uninitialized'),
    createDomainLoading: false,
    updateDefaultDomain: () => Promise.reject('Uninitialized'),
    removeDomain: () => Promise.reject('Uninitialized'),
    updateDefaultDisplayName: () => Promise.reject('Uninitialized'),
    updateDefaultName: () => Promise.reject('Uninitialized'),
    createDiscordBotConfiguration: () => Promise.reject('Uninitialized'),
    updateDiscordBotConfiguration: () => Promise.reject('Uninitialized'),
    removeDiscordBotConfiguration: () => Promise.reject('Uninitialized'),
    showLimitAlert: false,
    setShowLimitAlert: () => null,
  });

export const useGetTenantUsersData = () => {
  const { data, loading, refetch } = useQuery<TenantUsersResponse>(
    TenantUsersQuery,
    {
      variables: {
        filterRoles: [
          ROLES.admin.value,
          ROLES.developer.value,
          ROLES.marketer.value,
        ],
      },
    },
  );

  return { data, loading, refetch };
};

export const useGetUserProfile = () => {
  const { data, loading } =
    useQuery<UserProfileQueryResponse>(UserProfileQuery);
  return { data, loading };
};

export const useGet2FAStatus = () => {
  const { data, loading } = useQuery<Totp2FaRequiredForEmailLoginsQuery>(
    totp2FARequiredForEmailLoginsQuery,
  );
  return { data, loading };
};

export const use2FASetupCheck = () => {
  const { data, loading } = useQuery<Is2faQuery>(is2faQuery);
  return { data, loading };
};

export const useGetBotsData = (botType: BotType) => {
  const { data } = useQuery<BotsByTypeResponse>(BotsByTypeQuery, {
    variables: {
      GetBotsByTypeInput: {
        botType,
      },
    },
  });

  return { data };
};

export const useGetDiscordBotConfigurationData = () => {
  const { data } = useTenantDiscordBotConfigurationQuery();
  const redirectUrl = data?.tenantDiscordBotConfiguration?.redirectUrl ?? '';
  const discordClientId =
    data?.tenantDiscordBotConfiguration?.discordClientId ?? '';
  const discordGuildId =
    data?.tenantDiscordBotConfiguration?.discordGuildId ?? '';
  const discordServerInviteLink =
    data?.tenantDiscordBotConfiguration?.discordServerInviteLink ?? '';
  const name = data?.tenantDiscordBotConfiguration?.name ?? '';
  return {
    data: {
      redirectUrl,
      discordClientId,
      discordGuildId,
      discordServerInviteLink,
      name,
    },
  };
};

export const useGetAllEmailSenders = () => {
  const { data } = useAllEmailSendersQuery();
  return { data };
};

export const useGetEmailSenderDefaults = () => {
  const { data } = useGetEmailSenderDefaultsQuery();
  return { data: data?.emailSenderDefaults };
};

export const AccountSettingsDataContextProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const [showLimitAlert, setShowLimitAlert] = useState<boolean>(false);
  const [createBotMutation] = useAddBotMutation();
  const [updateBotMutation] = useUpdateBotMutation();
  const [removeBotMutation] = useRemoveBotMutation();
  const [createEmailSenderDomainMutation, { loading: createDomainLoading }] =
    useAddEmailSenderDomainMutation();
  const [removeEmailSenderDomainMutation] =
    useRemoveEmailSenderDomainMutation();
  const [updateDefaultEmailSenderDomainMutation] =
    useUpdateDefaultEmailSenderDomainMutation();
  const [updateDefaultEmailSenderDisplayNameMutation] =
    useUpdateDefaultEmailSenderDisplayNameMutation();
  const [updateDefaultEmailSenderNameMutation] =
    useUpdateDefaultEmailSenderNameMutation();
  const [createDiscordConfiguration] =
    useCreateDiscordBotConfigurationMutation();
  const [updateDiscordConfiguration] =
    useUpdateDiscordBotConfigurationMutation();
  const [deleteDiscordBotConfiguration] =
    useDeleteDiscordBotConfigurationMutation();

  const [createImpl, { loading: createUserLoading }] = useMutation<
    AddUserMutationResponse,
    { email: string; roles: string[] }
  >(AddUserMutation, {
    refetchQueries: [
      {
        query: TenantUsersQuery,
        variables: {
          filterRoles: [
            ROLES.admin.value,
            ROLES.developer.value,
            ROLES.marketer.value,
          ],
        },
      },
    ],
  });

  const [removeImpl, { loading: removeUserLoading }] = useMutation<
    RemoveUserRolesMutationResponse,
    { email: string; roles: string[] }
  >(RemoveUserRoles, {
    refetchQueries: [
      {
        query: TenantUsersQuery,
        variables: {
          filterRoles: [
            ROLES.admin.value,
            ROLES.developer.value,
            ROLES.marketer.value,
          ],
        },
      },
    ],
  });

  const createOrUpdateUser = useCallback(
    (email: string, roles: string[]) => {
      return createImpl({
        variables: {
          email,
          roles,
        },
      }).then((res) => {
        if (
          res?.data?.createOrUpdateUser?.errors !== null &&
          res?.data?.createOrUpdateUser?.errors?.[0]?.__typename ===
            'LimitedResourceTypeError'
        ) {
          setShowLimitAlert(true);
        }
        return res;
      });
    },
    [createImpl],
  );

  const removeUser = useCallback(
    (email: string, roles: string[]) => {
      return removeImpl({
        variables: {
          email,
          roles,
        },
      }).then((r) => r);
    },
    [removeImpl],
  );

  const removeBot = useCallback(
    (id: string) => {
      return removeBotMutation({
        variables: { id },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
        ],
      }).then((r) => r);
    },
    [removeBotMutation],
  );

  const createBot = useCallback(
    (credentials: string, botType: string, name: string) => {
      return createBotMutation({
        variables: {
          botCredentials: credentials,
          botType: botType as BotType,
          name,
        },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
        ],
      }).then((r) => r);
    },
    [createBotMutation],
  );

  const updateBot = useCallback(
    (id: string, name: string, credentials?: string) => {
      return updateBotMutation({
        variables: {
          id,
          name,
          botCredentials: credentials,
        },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
        ],
      }).then((r) => r);
    },
    [updateBotMutation],
  );

  const createDiscordBotConfiguration = useCallback(
    (
      discordClientId: string,
      discordClientSecret: string,
      discordGuildId: string,
      discordServerInviteLink: string,
      redirectUrl: string,
      name: string,
    ) => {
      return createDiscordConfiguration({
        variables: {
          discordClientId,
          discordClientSecret,
          discordGuildId,
          discordServerInviteLink,
          redirectUrl,
          name,
        },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
          {
            query: TenantDiscordBotConfigurationDocument,
          },
        ],
      }).then((r) => r);
    },
    [createDiscordConfiguration],
  );

  const updateDiscordBotConfiguration = useCallback(
    (
      discordGuildId: string,
      discordServerInviteLink: string,
      name: string,
      redirectUrl: string,
    ) => {
      return updateDiscordConfiguration({
        variables: {
          discordGuildId,
          discordServerInviteLink,
          name,
          redirectUrl,
        },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
          {
            query: TenantDiscordBotConfigurationDocument,
          },
        ],
      }).then((r) => r);
    },
    [updateDiscordConfiguration],
  );

  const removeDiscordBotConfiguration = useCallback(() => {
    return deleteDiscordBotConfiguration({
      refetchQueries: [
        {
          query: AllEmailSendersQuery,
        },
      ],
    }).then((r) => r);
  }, [deleteDiscordBotConfiguration]);

  const createOrUpdateDomain = useCallback(
    (domainName: string) => {
      return createEmailSenderDomainMutation({
        variables: {
          domainName,
        },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
        ],
      }).then((r) => r);
    },
    [createEmailSenderDomainMutation],
  );

  const updateDefaultDomain = useCallback(
    (id: string) => {
      return updateDefaultEmailSenderDomainMutation({
        variables: { defaultDomainId: id },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
          {
            query: EmailSenderDefaultsQuery,
          },
        ],
      })
        .then((r) => r)
        .catch((error) => {
          return error;
        });
    },
    [updateDefaultEmailSenderDomainMutation],
  );

  const updateDefaultDisplayName = useCallback(
    (displayName: string) => {
      return updateDefaultEmailSenderDisplayNameMutation({
        variables: { defaultDisplayName: displayName },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
          {
            query: EmailSenderDefaultsQuery,
          },
        ],
      })
        .then((r) => r)
        .catch((error) => {
          return error;
        });
    },
    [updateDefaultEmailSenderDisplayNameMutation],
  );

  const updateDefaultName = useCallback(
    (name: string) => {
      return updateDefaultEmailSenderNameMutation({
        variables: { defaultName: name },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
          {
            query: EmailSenderDefaultsQuery,
          },
        ],
      })
        .then((r) => r)
        .catch((error) => {
          return error;
        });
    },
    [updateDefaultEmailSenderNameMutation],
  );

  const removeDomain = useCallback(
    (id: string) => {
      return removeEmailSenderDomainMutation({
        variables: { domainNameId: id },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
        ],
      }).then((r) => r);
    },
    [removeEmailSenderDomainMutation],
  );

  return (
    <AccountSettingsDataContext.Provider
      value={{
        createOrUpdateUser,
        createUserLoading,
        removeUserLoading,
        removeUser,
        createBot,
        updateBot,
        removeBot,
        createOrUpdateDomain,
        createDomainLoading,
        updateDefaultDomain,
        removeDomain,
        updateDefaultDisplayName,
        updateDefaultName,
        createDiscordBotConfiguration,
        updateDiscordBotConfiguration,
        removeDiscordBotConfiguration,
        showLimitAlert,
        setShowLimitAlert,
      }}
    >
      {children}
    </AccountSettingsDataContext.Provider>
  );
};

export const useAccountSettingsDataContext =
  (): AccountSettingsDataContextState => {
    return useContext(AccountSettingsDataContext);
  };
