import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { Box, Grid, Typography, Link, SxProps } from '@mui/material';
import React from 'react';
import { dusk } from 'src/constants/colors';
import { FAQ_URL } from 'src/constants/urls';

type Props = {
  sx?: SxProps;
  linkSx?: SxProps;
};

export const Footer: React.FC<Props> = ({ sx, linkSx }) => {
  const { isAP2Enabled } = useFeatureFlag();
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        backgroundColor: isAP2Enabled ? '#fff' : '#292C4D',
        position: 'relative',
        bottom: 0,
        paddingX: '7%',
        py: '20px',
        height: { md: '54px', xs: 'auto' },
        ...sx,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ md: 'row', xs: 'column' }}
        rowGap="15px"
        flexWrap="nowrap"
        sx={{
          fontFamily: 'Rota',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: dusk,
            fontSize: '14px',
            fontWeight: 800,
            fontFamily: 'inherit',
            width: '180px',
            wordWrap: 'no wrap',
          }}
        >
          © {new Date().getFullYear()} Notifi Network, Inc
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: { md: '71px', xs: '20px' },
            fontFamily: 'inherit',
          }}
        >
          <Link
            href="https://notifi.network/terms"
            target="_blank"
            sx={{
              color: dusk,
              fontSize: '14px',
              fontWeight: 800,
              width: '110px',
              ...linkSx,
            }}
            underline="none"
          >
            Terms of Service
          </Link>
          <Link
            href="https://notifi.network/privacy"
            target="_blank"
            sx={{
              color: dusk,
              fontSize: '14px',
              fontWeight: 800,
              width: '100px',
              ...linkSx,
            }}
            underline="none"
          >
            Privacy Policy
          </Link>
          <Link
            href={FAQ_URL}
            target="_blank"
            sx={{
              color: dusk,
              fontSize: '14px',
              fontWeight: 800,
              width: '40px',
              ...linkSx,
            }}
            underline="none"
          >
            FAQs
          </Link>
          <Link
            href="https://docs.notifi.network/docs/"
            target="_blank"
            sx={{
              color: dusk,
              fontSize: '14px',
              fontWeight: 800,
              width: '40px',
              ...linkSx,
            }}
            underline="none"
          >
            Docs
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};
