import React from 'react';
import { useFeatureFlag } from './FeatureFlagContext';
import { Box, Stack, Typography } from '@mui/material';
import {
  blueglass,
  midnightDarker,
  midnightLight,
  Sapphire,
  white,
} from 'src/constants/colors';
import NotifiLogo from '../../assets/notifi-logo-new.svg';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Footer } from '@components/footer';

interface FlagCheckerProps {
  children: React.ReactNode;
}

const FlagChecker: React.FC<FlagCheckerProps> = ({ children }) => {
  const { isFlagVersionQueryFailed } = useFeatureFlag();

  if (isFlagVersionQueryFailed)
    return (
      <Stack
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: midnightDarker,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '40px',
            mt: '194px',
          }}
        >
          <img
            src={NotifiLogo}
            alt="Notifi Logo"
            style={{ width: '175px', height: '60px' }}
          />

          <Typography
            sx={{
              color: white,
              textAlign: 'center',
              fontSize: '22px',
              fontWeight: 900,
              letterSpacing: '-0.185px',
            }}
          >
            Page failed to load, please try again.
          </Typography>

          <PrimaryButton
            buttonTitle={'Reload'}
            customSx={{
              marginX: 'auto',
              width: 147,
              color: white,
              fontSize: '16px',
              fontWeight: 700,
              backgroundColor: blueglass,
              '&:hover': {
                backgroundColor: Sapphire,
              },
            }}
            onClick={() => window.location.reload()}
          />
        </Box>

        <Box sx={{ width: '100%', position: 'absolute', bottom: 0 }}>
          <Footer
            sx={{
              width: 'unset',
              backgroundColor: midnightLight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '54px',
              py: 'unset',
            }}
            linkSx={{
              fontSize: '12px',
              textDecoration: 'underline',
            }}
          />
        </Box>
      </Stack>
    );
  return <>{children}</>;
};

export default FlagChecker;
