import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useTenantFeatureFlagQuery } from 'src/services/gql/generated';

export type FeatureFlagState = Readonly<{
  isAP2Enabled: boolean;
  isFlagVersion19: boolean;
  isFlagVersion195: boolean;
  loaded: boolean;
  isMultilevelTokenFlowForEmailEnabled: boolean;
  canRequire2FA: boolean;
  canFilterOnTag: boolean;
  isCM3Enabled: boolean;
  isNotifiHostedEnabled: boolean;
  isFlagVersionQueryFailed: boolean;
}>;

const FeatureFlagContext = createContext<FeatureFlagState>({
  isAP2Enabled: false,
  isFlagVersion19: false,
  isFlagVersion195: false,
  loaded: false,
  isMultilevelTokenFlowForEmailEnabled: false,
  canRequire2FA: false,
  canFilterOnTag: false,
  isCM3Enabled: false,
  isNotifiHostedEnabled: false,
  isFlagVersionQueryFailed: false,
});

function parseFeatureFlagToBoolean(value: string | undefined) {
  if (value === 'false') return false;
  return !!value;
}

export const FeatureFlagProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const { data, loading } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'AdminPanel' },
    },
  });

  const { data: multilevelTokenFlowForEmailEnabledData } =
    useTenantFeatureFlagQuery({
      variables: {
        TenantFeatureFlagInput: {
          tenantFeatureFlagName: 'multilevelTokenFlowForEmail',
        },
      },
    });

  const { data: canRequire2FAData } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'canRequire2FA' },
    },
  });

  const { data: canFilterOnTagData } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'canFilterOnTag' },
    },
  });

  let isAP2Enabled = false;
  let isFlagVersion19 = false;
  let isFlagVersion195 = true;
  let isCM3Enabled = false;
  let isNotifiHostedEnabled = false;
  let isFlagVersionQueryFailed = false;
  const featureFlagValue = JSON.parse(data?.tenantFeatureFlag?.value || '{}');

  if (featureFlagValue?.version) {
    isAP2Enabled =
      featureFlagValue.version === '1.9' ||
      featureFlagValue.version === '1.95' ||
      featureFlagValue.version === '2.0' ||
      featureFlagValue.version === '3.0';

    isFlagVersion19 = featureFlagValue.version === '1.9';
    isFlagVersion195 = featureFlagValue.version === '1.95';
    isNotifiHostedEnabled =
      featureFlagValue.version === '2.0' || featureFlagValue.version === '3.0';
    isCM3Enabled =
      featureFlagValue.version === '1.9' ||
      featureFlagValue.version === '1.95' ||
      featureFlagValue.version === '2.0' ||
      featureFlagValue.version === '3.0';
  } else if (!loading) {
    isFlagVersionQueryFailed = true;
  }

  const loaded = !loading;
  const isMultilevelTokenFlowForEmailEnabled = parseFeatureFlagToBoolean(
    multilevelTokenFlowForEmailEnabledData?.tenantFeatureFlag.value,
  );
  const canRequire2FA = parseFeatureFlagToBoolean(
    canRequire2FAData?.tenantFeatureFlag.value,
  );
  const canFilterOnTag = parseFeatureFlagToBoolean(
    canFilterOnTagData?.tenantFeatureFlag.value,
  );

  return (
    <FeatureFlagContext.Provider
      value={{
        isAP2Enabled,
        isFlagVersion19,
        isFlagVersion195,
        loaded,
        isMultilevelTokenFlowForEmailEnabled,
        canRequire2FA,
        canFilterOnTag,
        isCM3Enabled,
        isNotifiHostedEnabled,
        isFlagVersionQueryFailed,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = (): FeatureFlagState => {
  return useContext(FeatureFlagContext);
};
