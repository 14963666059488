import { PreviewButton } from '@components/buttons/PreviewButton';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import ContentEditor from '@components/content-editor';
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  cloud,
  dusk,
  eggshell,
  midnight,
  midnightDarker,
  midnightLight,
  white,
} from 'src/constants/colors';
import {
  OptionsType,
  isEmptyContent,
} from 'src/pages/Community/CreateMessageForm';
import {
  CustomHtmlPayload,
  MessageVariables,
} from 'src/pages/Community/MessageService';
import {
  TemplateType,
  WalletBlockchain,
  useFusionPreviewCommunityManagerContentLazyQuery,
  usePreviewCommunityManagerContentLazyQuery,
} from 'src/services/gql/generated';
import PreviewDialog from 'src/pages/Community/PreviewDialog';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-seaglass.svg';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import TurndownService from 'turndown';
import { isCustomHTMLEnabled } from 'src/pages/featureFlags';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type EmailComposerProps = Readonly<{
  selectedFormat: string;
  setSelectedFormat: (selectedFormat: string) => void;
  subject: string;
  setSubject: (subject: string) => void;
  emailMessageContent: string;
  setEmailMessageContent: (emailMessageContent: string) => void;
  selectedTopicKey?: string;
  customHTML: string;
  setCustomHTML: (customHTML: string) => void;
  unstyledMessage: string;
  setUnstyledMessage: (unstyledMessage: string) => void;
  isDefineTemplate?: boolean;
  isCommunityManager: boolean;
}>;

export const EmailComposer: React.FC<EmailComposerProps> = ({
  selectedFormat,
  setSelectedFormat,
  subject,
  setSubject,
  emailMessageContent,
  setEmailMessageContent,
  selectedTopicKey,
  customHTML,
  setCustomHTML,
  unstyledMessage,
  setUnstyledMessage,
  isDefineTemplate,
  isCommunityManager,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [emailTemplate, setEmailTemplate] = useState<string>('');
  const [contentFormat, setContentFormat] = useState<string[]>([]);

  const [getContent] = usePreviewCommunityManagerContentLazyQuery();
  const [getFusionPreviewContent] =
    useFusionPreviewCommunityManagerContentLazyQuery();
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const options: OptionsType = {
    emDelimiter: '*',
    strongDelimiter: '**',
  };
  const turndownService = new TurndownService(options);

  const timestamp = Math.round(Date.now() / 1000);

  useEffect(() => {
    if (isCommunityManager) {
      setContentFormat(['Basic Notifi Template', 'Custom HTML']);
    } else {
      setContentFormat(['Custom HTML']);
    }
  }, [isCommunityManager]);
  const isEmailPreviewEnabled = () => {
    if (selectedFormat === 'Basic Notifi Template') {
      setCustomHTML('');
      setUnstyledMessage('');
      return (
        !isEmptyContent(emailMessageContent) &&
        subject.length > 1 &&
        selectedTopicKey !== ''
      );
    } else if (selectedFormat === 'Custom HTML') {
      setEmailMessageContent('');
      return (
        customHTML !== '' &&
        unstyledMessage !== '' &&
        subject.length > 1 &&
        selectedTopicKey !== ''
      );
    }
  };

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const handlePreview = async () => {
    clearMessageBarState();
    let variables: MessageVariables[] = [];
    let customHtmlPayload: CustomHtmlPayload | undefined = {
      htmlMessage: '',
      unstyledMessage: '',
    };
    if (
      selectedFormat === 'Basic Notifi Template' &&
      !isEmptyContent(emailMessageContent) &&
      subject !== '' &&
      selectedTopicKey !== ''
    ) {
      variables = [
        {
          key: 'subject',
          value: subject,
        },
        {
          key: 'message__markdown',
          value: turndownService.turndown(emailMessageContent),
        },
      ];
      customHtmlPayload = undefined;
    } else if (
      selectedFormat === 'Custom HTML' &&
      customHTML !== '' &&
      unstyledMessage !== '' &&
      subject !== '' &&
      selectedTopicKey !== ''
    ) {
      variables = [
        {
          key: 'subject',
          value: subject,
        },
      ];
      customHtmlPayload = {
        htmlMessage: customHTML,
        unstyledMessage,
      };
    }
    const queryVariables = {
      previewInput: {
        previewTarget: TemplateType.EMAIL,
        sourceAddress: selectedTopicKey ?? '',
        timestamp,
        variables,
        customHtmlPayload,
        walletBlockchain: WalletBlockchain.OFF_CHAIN,
      },
    };
    const { data, error, loading } = await getContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { previewCommunityManagerContent } = data;
      setEmailTemplate(previewCommunityManagerContent.content);
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };

  const handleFusionPreview = async () => {
    clearMessageBarState();
    let variables: MessageVariables[] = [];
    if (
      selectedFormat === 'Basic Notifi Template' &&
      !isEmptyContent(emailMessageContent) &&
      subject !== '' &&
      selectedTopicKey !== ''
    ) {
      variables = [
        {
          key: 'subject',
          value: subject,
        },
        {
          key: 'message__markdown',
          value: turndownService.turndown(emailMessageContent),
        },
      ];
      const queryVariables = {
        fusionCommunityManagerPreviewInput: {
          previewTarget: TemplateType.EMAIL,
          targetTemplateId: selectedTopicKey ?? '',
          variables,
        },
      };
      const { data, error, loading } = await getFusionPreviewContent({
        variables: queryVariables,
      });
      if (!loading && data) {
        setIsPreviewModalOpen(true);
        const { fusionPreviewCommunityManagerContent } = data;
        setEmailTemplate(fusionPreviewCommunityManagerContent.content);
      } else if (error) {
        setErrorMessage('There was an error generating a preview. Try again!');
      }
    } else if (
      selectedFormat === 'Custom HTML' &&
      customHTML !== '' &&
      unstyledMessage !== '' &&
      subject !== '' &&
      selectedTopicKey !== ''
    ) {
      setIsPreviewModalOpen(true);
      setEmailTemplate(customHTML);
    }
  };

  return (
    <>
      <Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: 'Rota',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            Email
          </Typography>
          {isDefineTemplate ? null : (
            <PreviewButton
              disabled={!isEmailPreviewEnabled()}
              handlePreview={isAP2Enabled ? handleFusionPreview : handlePreview}
              customSx={{ marginRight: 0, marginBottom: 0 }}
            />
          )}
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={1}
        >
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            Subject
          </Typography>
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '12px',
              fontWeight: 800,
              lineHeight: '18px',
            }}
          >
            max 200 characters
          </Typography>
        </Stack>
      </Stack>
      <TextField
        color="secondary"
        disabled={false}
        fullWidth
        hiddenLabel
        inputProps={{
          autoComplete: 'off',
          maxLength: 200,
        }}
        onChange={(e) => {
          setSubject(e.target.value);
          clearMessageBarState();
        }}
        placeholder="Subject"
        size="small"
        sx={{
          ...(isAP2Enabled
            ? {
                '& input': {
                  fontFamily: 'Rota',
                  fontWeight: 600,
                  color: midnight,
                  padding: '13px 20px',
                  lineHeight: 'normal',
                },
                '& input::placeholder': {
                  color: dusk,
                  opacity: 1,
                },
                backgroundColor: eggshell,
              }
            : {
                '& input': {
                  fontFamily: 'Rota',
                  fontWeight: 600,
                },
                backgroundColor: midnightDarker,
              }),
        }}
        value={subject}
        variant="outlined"
      />
      {isCustomHTMLEnabled ? (
        <>
          <FormControl sx={{ mt: 2, width: '100%' }}>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Content Format
            </Typography>
            <Select
              displayEmpty
              labelId="topic-input-label"
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: isAP2Enabled ? eggshell : midnightLight,
                      p: 1,
                    },
                  },
                },
              }}
              IconComponent={(props) => {
                return (
                  <IconChevronDown
                    {...props}
                    style={{
                      marginRight: '12px',
                      top: 'calc(50% - .7em)',
                      fill: isAP2Enabled ? dusk : white,
                    }}
                  />
                );
              }}
              onChange={(e) => {
                setSelectedFormat(e.target.value ?? '');
                clearMessageBarState();
              }}
              renderValue={(value) => {
                return value;
              }}
              size="small"
              sx={{
                fontFamily: 'Rota',
                fontWeight: 600,
                fontSize: '16px',
                ...(isAP2Enabled
                  ? {
                      color: midnight,
                      backgroundColor: eggshell,
                      '& .MuiSelect-select': {
                        padding: '13px 20px',
                      },
                      '& input': { lineHeight: 'normal' },
                    }
                  : { backgroundColor: midnightDarker }),
              }}
              value={selectedFormat}
            >
              {contentFormat.map((format) => {
                return (
                  <MenuItem
                    key={format}
                    selected={selectedFormat === format}
                    sx={{
                      color: isAP2Enabled ? midnight : eggshell,
                      fontFamily: 'Rota',
                      fontWeight: 600,
                      fontSize: '16px',
                      '&:hover': {
                        background: isAP2Enabled
                          ? 'rgba(144, 202, 249, 0.16)'
                          : midnight,
                      },
                    }}
                    value={format}
                  >
                    <Stack
                      alignItems="center"
                      flexDirection="row"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      {format}
                      {selectedFormat === format ? <CheckIcon /> : null}
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </>
      ) : null}
      {selectedFormat === 'Basic Notifi Template' ? (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                mt: 2,
              }}
            >
              Content
            </Typography>

            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 12k characters
            </Typography>
          </Stack>
          <ContentEditor
            setValue={setEmailMessageContent}
            value={emailMessageContent}
            destination="email"
            isCommunityManager={isCommunityManager}
            maxLimit={12000}
          />
        </>
      ) : null}
      {selectedFormat === 'Custom HTML' ? (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
          >
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
              }}
            >
              Custom HTML
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontFamily: 'Rota',
                fontSize: '12px',
                fontWeight: 800,
                lineHeight: '18px',
              }}
            >
              max 16k characters
            </Typography>
          </Stack>

          <TextField
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            id="standard-multiline-static"
            inputProps={{
              py: 1.5,
              maxLength: 16000,
            }}
            multiline
            onChange={(e) => {
              setCustomHTML(e.target.value);
            }}
            placeholder="Insert your custom HTML"
            minRows={5}
            maxRows={26}
            size="small"
            sx={{
              '& fieldset': { borderRadius: '4px' },
              '& textarea': {
                fontFamily: 'Rota',
                fontWeight: 600,
                fontSize: '16px',
              },
              ...(isAP2Enabled
                ? {
                    '& .MuiInputBase-root': {
                      color: midnight,
                    },
                    mb: 3,
                    backgroundColor: eggshell,
                  }
                : { mb: 3, backgroundColor: midnightDarker }),
            }}
            value={customHTML}
            variant="outlined"
          />
          {/* <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            Unstyled Message
          </Typography>
          <TextField
            color="secondary"
            disabled={false}
            fullWidth
            hiddenLabel
            id="standard-multiline-static"
            inputProps={{
              maxLength: 2000,
              py: 1.5,
            }}
            multiline
            onChange={(e) => {
              setUnstyledMessage(e.target.value);
            }}
            placeholder="Add your email text that will be rendered in the event that a recipient’s client does not support html."
            minRows={5}
            size="small"
            sx={{
              '& fieldset': { borderRadius: '4px' },
              '& textarea': {
                fontFamily: 'Rota',
                fontWeight: 600,
                fontSize: '16px',
              },
              ...(isAP2Enabled
                ? {
                    '& .MuiInputBase-root': {
                      color: midnight,
                    },
                    mb: 3,
                    backgroundColor: eggshell,
                  }
                : { mb: 3, backgroundColor: midnightDarker }),
            }}
            value={unstyledMessage}
            variant="outlined"
          /> */}
        </>
      ) : null}
      {isDefineTemplate ? null : (
        <Divider
          sx={{
            borderColor: isAP2Enabled ? cloud : 'rgba(255, 255, 255, 0.12)',
            borderWidth: isAP2Enabled ? '1.5px' : '1px',
          }}
        />
      )}
      <PreviewDialog
        destination="email"
        template={emailTemplate}
        handleClose={handleClosePreviewModal}
        open={isPreviewModalOpen}
      />
    </>
  );
};
