import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { dusk, midnight, midnightLight, white } from 'src/constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { Close } from '@mui/icons-material';
type Props = {
  handleClose: () => void;
  open: boolean;
};

const CreateCMTopicModal = ({ handleClose, open }: Props) => {
  const { isAP2Enabled } = useFeatureFlag();
  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth="lg"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: isAP2Enabled ? white : midnightLight,
            borderRadius: '4px',
            width: '600px',
          },
        }}
      >
        <Grid
          container
          flexWrap="nowrap"
          alignItems="start"
          justifyContent="center"
        >
          <DialogTitle
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              p: 0,
              textAlign: 'center',
              width: '100%',
              flex: '1 1 auto',
              ml: '64px',
              mr: '10px',
              pt: 4,
              color: midnight
            }}
          >
            First create a Community Manager topic
          </DialogTitle>

          <Box
            sx={{
              width: '25px',
              height: '25px',
              cursor: 'pointer',
              mr: 4,
              pt: 4,
            }}
            onClick={handleClose}
          >
            <Close sx={{ color: dusk }} />
          </Box>
        </Grid>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 0,
          }}
        >
          <Stack width={'100%'} mt={4} mb={4}>
            <Stack alignItems="center" justifyContent="center" flexGrow="1">
              <Typography
                fontSize={'16px'}
                fontWeight={500}
                textAlign={'center'}
                color={midnight}
              >
                Before you create an easy subscribe config you need to create at
                least one Community Manager topic in the Alert Manager.
              </Typography>
            </Stack>
          </Stack>

          <SecondaryButton
            customSx={{ width: '156px', mb: 4 }}
            buttonTitle="Got It"
            onClick={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateCMTopicModal;
