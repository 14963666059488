import { useState, useEffect, useCallback } from 'react';
import Web3, { AbiFunctionFragment } from 'web3';
const web3 = new Web3();
import { WalletBlockchain } from 'src/services/gql/generated';

const apiKeys = {
  [WalletBlockchain.ETHEREUM]: 'E5XC8BY6UEPBX9QKK1HQ97VN3EDWB6GRTH',
  [WalletBlockchain.ARBITRUM]: 'DP2GW8Z889I74UKVCCHSWDSE6RB1C73ESB',
  [WalletBlockchain.BINANCE]: 'JTRK4ZMMVMFPGCXTQZN2QR1DADNMKUQYKB',
  [WalletBlockchain.OPTIMISM]: 'WMGGJUZ98W667G3N3HHCE3UDEDX1695TER',
  [WalletBlockchain.POLYGON]: '6H4ADVUVW9T1I46KX33ZAZP8YCSVJMYVZV',
  [WalletBlockchain.AVALANCHE]: '',
  [WalletBlockchain.ZKSYNC]: '',
  [WalletBlockchain.EVMOS]: '',
  [WalletBlockchain.BASE]: '',
  [WalletBlockchain.ACALA]: '',
  [WalletBlockchain.APTOS]: '',
  [WalletBlockchain.AXELAR]: '',
  [WalletBlockchain.INJECTIVE]: '',
  [WalletBlockchain.NEAR]: '',
  [WalletBlockchain.NIBIRU]: '',
  [WalletBlockchain.OSMOSIS]: '',
  [WalletBlockchain.SOLANA]: '',
  [WalletBlockchain.SUI]: '',
  [WalletBlockchain.OFF_CHAIN]: '',
  [WalletBlockchain.BLAST]: '',
  [WalletBlockchain.CELO]: '',
  [WalletBlockchain.LINEA]: '',
  [WalletBlockchain.MANTA]: '',
  [WalletBlockchain.MANTLE]: '',
  [WalletBlockchain.SCROLL]: '',
  [WalletBlockchain.MONAD]: '',
  [WalletBlockchain.XION]: '',
  [WalletBlockchain.ELYS]: '',
  [WalletBlockchain.ARCHWAY]: '',
  [WalletBlockchain.BERACHAIN]: '',
  [WalletBlockchain.NEUTRON]: '',
  [WalletBlockchain.AGORIC]: '',
  [WalletBlockchain.CELESTIA]: '',
  [WalletBlockchain.COSMOS]: '',
  [WalletBlockchain.DYDX]: '',
  [WalletBlockchain.DYMENSION]: '',
  [WalletBlockchain.KAVA]: '',
  [WalletBlockchain.ORAI]: '',
  [WalletBlockchain.PERSISTENCE]: '',
  [WalletBlockchain.THE_ROOT_NETWORK]: '',
  [WalletBlockchain.MOVEMENT]: '',
  [WalletBlockchain.ARCH]: '',
  [WalletBlockchain.BITCOIN]: '',
  [WalletBlockchain.ROME]: '',
  [WalletBlockchain.SONIC]: '',
};

const getChainEndpoint = (chain: WalletBlockchain, address: string) => {
  const apiKey = apiKeys[chain] ?? '';

  switch (chain) {
    case WalletBlockchain.ETHEREUM:
      return `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.ARBITRUM:
      return `https://api.arbiscan.io/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.BINANCE:
      return `https://api.bscscan.com/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.OPTIMISM:
      return `https://api-optimistic.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.POLYGON:
      return `https://api.polygonscan.com/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.AVALANCHE:
      return `https://api.routescan.io/v2/network/mainnet/evm/43114/etherscan/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.ZKSYNC:
      return `https://api.routescan.io/v2/network/mainnet/evm/324/etherscan/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
    case WalletBlockchain.BASE:
      return `https://api.routescan.io/v2/network/mainnet/evm/8453/etherscan/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;

    default:
      return '';
  }
};

const useAvailableEvents = () => {
  const [abi, setABI] = useState<string>('');
  const [contractAddress, setContractAddress] = useState<string>('');
  const [selectedChain, setSelectedChain] = useState<WalletBlockchain>(
    WalletBlockchain.ETHEREUM,
  );
  const [availableEvents, setAvailableEvents] = useState<
    (AbiFunctionFragment & { eventHash: string })[]
  >([]);

  const getAvailableEventsFromABI = useCallback((abiData: string) => {
    try {
      const apiJSON = JSON.parse(abiData) as AbiFunctionFragment[];
      const abiEvents = apiJSON
        .filter((item) => item.type === 'event')
        .map((item) => ({
          ...item,
          eventHash: web3.eth.abi.encodeEventSignature(item),
        }));

      setAvailableEvents(abiEvents);
    } catch (e) {
      setAvailableEvents([]);
    }
  }, []);

  const getABIFromContractAddress = useCallback(
    async (chain: WalletBlockchain, address: string) => {
      const endpoint = getChainEndpoint(chain, address);
      if (!endpoint) return;

      try {
        const response = await fetch(endpoint).then((res) => res.json());
        getAvailableEventsFromABI(response.result);
      } catch (e) {
        setAvailableEvents([]);
      }
    },
    [getAvailableEventsFromABI],
  );

  useEffect(() => {
    setAvailableEvents([]);
    if (!contractAddress) return;

    const timer = setTimeout(
      () => getABIFromContractAddress(selectedChain, contractAddress),
      1000,
    );
    return () => clearTimeout(timer);
  }, [contractAddress, getABIFromContractAddress, selectedChain]);

  useEffect(() => {
    setAvailableEvents([]);
    if (!abi) return;

    const timer = setTimeout(() => getAvailableEventsFromABI(abi), 1000);
    return () => clearTimeout(timer);
  }, [abi, getAvailableEventsFromABI]);

  return {
    abi,
    contractAddress,
    availableEvents,
    setABI,
    setContractAddress,
    setSelectedChain,
  };
};

export default useAvailableEvents;
