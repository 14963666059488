import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import { dusk, eggshell, midnight, rose, white } from 'src/constants/colors';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import useAuthenticatorCode from './useAuthenticatorCode';
import {
  useBeginAddTotp2FaMutation,
  useCompleteAddTotp2FaMutation,
  useEnableTotp2FaRequiredForEmailLoginsMutation,
} from 'src/services/gql/generated';
import { QRCodeSVG } from 'qrcode.react';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { useAuthContext } from './AuthContext';
import { Theme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { is2faQuery } from 'src/services/gql/queries/Is2FaQuery.gql';
import { useGetUserProfile } from 'src/pages/AccountSettings/AccountSettingsDataContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TwoFactorCodeInputProps {
  code: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  sx?: SxProps<Theme>;
}

export const TwoFactorCodeInput: React.FC<TwoFactorCodeInputProps> = ({
  code,
  handleChange,
  error,
  sx,
}) => {
  return (
    <FormControl sx={{ width: '100%' }} error={!!error}>
      <Typography
        sx={{ ml: 0, color: rose, fontWeight: 700, fontSize: '14px' }}
      >
        {error}
      </Typography>
      <TextField
        id="auth-code"
        variant="outlined"
        value={code}
        onChange={handleChange}
        placeholder="000000"
        error={!!error}
        sx={{
          width: '100%',
          borderRadius: '10px',
          '& .MuiInputBase-input': {
            backgroundColor: eggshell,
            color: midnight,
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: rose, // Set your desired error border color here
            },
          },
          ...sx,
        }}
      />
    </FormControl>
  );
};

export type SecureAccountMode = 'setup' | 'regenerateCodes';

interface SecureAccountProps {
  open: boolean;
  handleClose: () => void;
  mode?: SecureAccountMode;
  regeneratedBackupCodes?: string[];
}

export const SecureAccount: React.FC<SecureAccountProps> = ({
  open,
  handleClose,
  mode,
  regeneratedBackupCodes,
}) => {
  const { code, error, handleChange, setError, hasEmptyFields } =
    useAuthenticatorCode();
  const authContext = useAuthContext();
  const location = useLocation();
  const { email } = location.state || {};
  const { data } = useGetUserProfile();
  const [
    beginAddTotp2FaMutation,
    { data: beginAddTotp2FaData, loading: isBeginAddTotp2FaDataLoading },
  ] = useBeginAddTotp2FaMutation({
    variables: {},
  });

  const [
    completeAddTotp2FaMutation,
    { loading: isCompleteAddTotp2FaMutationLoading },
  ] = useCompleteAddTotp2FaMutation({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        totpCode: code,
      },
    },
  });

  const [enableTotp2FaRequiredForEmailLoginsMutation] =
    useEnableTotp2FaRequiredForEmailLoginsMutation({
      variables: {},
      refetchQueries: [is2faQuery],
      fetchPolicy: 'no-cache',
    });

  const [backupCodes, setBackupCodes] = useState<string[]>([]);

  const handleCloseButton = () => {
    if (authContext.type === 'loggedOut' && backupCodes.length > 0) {
      authContext.loginWith2FaCode(code);
    } else {
      handleClose();
    }
  };

  const handleDone = () => {
    handleCloseButton();
  };

  const beginAddTotp2FA = () => {
    beginAddTotp2FaMutation();
  };

  const handleCompleteAddTotp2Fa = () => {
    completeAddTotp2FaMutation()
      .then(({ data }) => {
        if (data?.completeAddTOTP2FA.backupCodes) {
          setBackupCodes(data?.completeAddTOTP2FA.backupCodes);
        }
        if (authContext.type === 'loggedIn') {
          enableTotp2FaRequiredForEmailLoginsMutation();
        }
      })
      .catch((err) => {
        setError(err?.networkError?.result?.errors[0]?.message);
      });
  };

  const handleCopyToClipboard = () => {
    const formattedCodes = backupCodes.join('\n');
    navigator.clipboard
      .writeText(formattedCodes)
      .catch((err) => {
        console.error('Failed to copy text to clipboard:', err);
      });
  };

  useEffect(() => {
    if (mode === 'regenerateCodes') {
      if (regeneratedBackupCodes) {
        setBackupCodes(regeneratedBackupCodes);
      }
    }
  }, [regeneratedBackupCodes]);

  useEffect(() => {
    if (mode === 'setup') {
      beginAddTotp2FA();
    }
  }, []);

  const qrCodeString = `otpauth://totp/Notifi:${
    email || data?.userProfile?.tenantInfo?.name
  }?secret=${beginAddTotp2FaData?.beginAddTOTP2FA.secret}&issuer=Notifi`;

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      onClose={handleClose}
      open={open}
      sx={{
        '&.MuiPaper-root &.MuiDialog-paper': {
          backgroundColor: 'transparent',
        },
        backgroundColor: '#F3F3FA',
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: '#F3F3FA',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          pb: 0,
        }}
      >
        <IconButton
          sx={{
            backgroundColor: white,
            borderRadius: '45px',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'absolute',
            right: '15%',
            top: '56px',
            '&:hover': {
              backgroundColor: white,
            },
          }}
          onClick={handleCloseButton}
        >
          <CloseIcon sx={{ color: dusk }} />
        </IconButton>

        <Typography
          variant="h1"
          fontSize={'30px'}
          sx={{ my: 3, color: midnight }}
        >
          Secure your account
        </Typography>

        {mode === 'setup' && backupCodes.length < 1 ? (
          <Stack
            flexDirection="column"
            justifyContent="center"
            sx={{
              width: '920px',
              backgroundColor: 'white',
              borderRadius: '8px',
              mb: 3,
              p: 4,
              px: 35,
            }}
          >
            <Typography variant="h1" fontSize="22px" color={midnight}>
              Set up Authenticator App
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2, mb: 2, color: midnight }}>
              <ListItem sx={{ display: 'list-item', color: midnight }}>
                <ListItemText
                  primaryTypographyProps={{ sx: { color: midnight } }}
                  primary="Install an authenticator app like Google Authenticator on your mobile device."
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', color: midnight }}>
                <ListItemText
                  primaryTypographyProps={{ sx: { color: midnight } }}
                  primary="Scan QR code with the authenticator (or tap it in mobile browser)."
                />
              </ListItem>
            </List>
            {isBeginAddTotp2FaDataLoading ? (
              <CircularProgress
                style={{ color: midnight, alignSelf: 'center', height: '800x' }}
              />
            ) : (
              <QRCodeSVG value={qrCodeString} />
            )}
            <Typography sx={{ my: 3, color: midnight }}>
              Enter your two-factor authentication code.
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TwoFactorCodeInput
                code={code}
                handleChange={handleChange}
                error={error}
              />
            </Box>
            <PrimaryButton
              disabled={
                isCompleteAddTotp2FaMutationLoading ||
                hasEmptyFields ||
                isBeginAddTotp2FaDataLoading
              }
              buttonTitle="Enable"
              onClick={handleCompleteAddTotp2Fa}
              customSx={{
                alignSelf: 'center',
                color: white,
                marginX: 'auto',
                width: '50%',
                backgroundColor: midnight,
                '&:hover': {
                  backgroundColor: midnight,
                },
                '&:disabled': {
                  backgroundColor: midnight,
                  color: white,
                  opacity: 0.4,
                },
              }}
            />
          </Stack>
        ) : (
          <Stack
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '920px',
              backgroundColor: 'white',
              borderRadius: '8px',
              mb: 3,
              p: 4,
              px: 30, // Adjust padding for better alignment
            }}
          >
            <Typography color={midnight} sx={{ mb: 2 }}>
              Download your backup codes. You can use each code once.
            </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              gap={2}
              sx={{ my: 4, width: '400px', margin: '0 auto' }}
            >
              {backupCodes.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 'calc(50% - 8px)', // Adjust to ensure two items per row with gap
                    textAlign: 'center',
                    padding: '10px',
                    backgroundColor: eggshell,
                  }}
                >
                  <Typography color={midnight} fontWeight={'bold'}>
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Stack
              alignItems="center"
              direction={'row'}
              gap={3}
              sx={{
                my: 4,
                width: '400px',
              }}
            >
              <SecondaryButton
                customSx={{
                  width: '50%',
                  color: midnight,
                  border: `1px solid ${midnight}`,
                }}
                buttonTitle="Copy to clipboard"
                onClick={handleCopyToClipboard}
              />

              <PrimaryButton
                customSx={{
                  width: '50%',
                  backgroundColor: midnight,
                  color: white,
                }}
                buttonTitle="Done"
                onClick={handleDone}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

SecureAccount.defaultProps = {
  mode: 'setup',
};
