import React, { useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useGetSubscriptionConfigData } from '@components/cardConfig/CardConfigDataContext';
import { dusk, midnight } from 'src/constants/colors';
import TopicsTab from './TopicsTab';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { CalloutTooltip } from '@components/alertManager/CalloutTooltip';
import CreateTopicModal from '@components/alertManager/CreateTopicModal';
import { PageHeaderSection } from '@components/section/Header';
import { useNavigate } from 'react-router-dom';
import { TABS } from '../AccountSettings/constants';
import ErrorsSection from './ErrorsSection';
import {
  FusionSourceState,
  useFusionEventsQuery,
} from 'src/services/gql/generated';
import useClickOutside from 'src/hooks/useClickOutside';
import { useAppContext } from 'src/context/AppContext';
import { useAuthContext } from '@components/auth/AuthContext';

type CursoInfo = {
  hasNextPage: boolean;
  endCursor?: string;
};

export const AlertManagerTopicsViewContainer: React.FC = () => {
  const cnfigUICardRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] =
    React.useState<boolean>(false);
  const [isShowTooltip, setShowToolTip] = React.useState<boolean>(true);
  const { isrefetchTopicsData, setRefetchTopicsData } = useAppContext();
  const { items: cardConfigData, isLoading: loadingSubscriptionConfigData } =
    useGetSubscriptionConfigData();
  const isInitialLoaded = React.useRef(false);
  const mainRef = React.useRef<HTMLDivElement>(null);
  const [topicsData, setTopicData] = React.useState<any[]>([]);
  const [isTopicLoading, setIsTopicLoading] = React.useState<boolean>(true);
  const [isLoadingMoreItems, setIsLoadingMoreItems] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [cursorInfo, setCursorInfo] = React.useState<CursoInfo>({
    hasNextPage: false,
    endCursor: undefined,
  });
  const authState = useAuthContext();
  const isMarketerRole = useMemo(() => {
    if (authState.type === 'loggedIn') {
      return Boolean(
        authState.roles?.includes('UserMessenger') &&
          authState.roles?.length === 2,
      );
    }
    return false;
  }, [authState]);
  const topicsCountPerPage = 20;
  const { loading: topicsDataIsLoading, refetch } = useFusionEventsQuery({
    variables: {
      first: topicsCountPerPage,
    },
  });

  useClickOutside(cnfigUICardRef, function () {
    setShowToolTip(false);
    localStorage.setItem('hasSeenConfigUICardTooltip', JSON.stringify(true));
  });

  const hasSeenConfigUICardTooltip = localStorage.getItem(
    'hasSeenConfigUICardTooltip',
  );

  useEffect(() => {
    // Disable scrolling on the parent (e.g., body)
    document.body.style.overflow = 'hidden';

    return () => {
      // Re-enable scrolling when the component is unmounted
      document.body.style.overflow = '';
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTopicItems = async (initialLoad?: boolean) => {
    if (!initialLoad && !cursorInfo.hasNextPage) {
      setError(new Error('No more notification history to fetch'));
      return;
    }
    if (isTopicLoading && isInitialLoaded.current) {
      return;
    }
    isInitialLoaded.current = true;
    setIsTopicLoading(true);
    try {
      const result = await refetch({
        first: topicsCountPerPage,
        after: isrefetchTopicsData ? undefined : cursorInfo.endCursor,
      });

      if (
        !result ||
        result.data.fusionEvents?.nodes?.length === 0 ||
        !result.data.fusionEvents?.nodes
      ) {
        return;
      }

      setTopicData((existing) =>
        initialLoad
          ? result.data.fusionEvents?.nodes ?? []
          : [...existing, ...(result.data.fusionEvents?.nodes ?? [])],
      );
      setCursorInfo(result.data.fusionEvents.pageInfo);
      setError(null);
    } catch (e) {
      setError(
        new Error(
          'ERROR: Failed to fetch notification history, please try again.',
        ),
      );
      console.error(e);
    } finally {
      setIsTopicLoading(false);
    }
  };

  useEffect(() => {
    // NOTE: Light weight implementation of infinite scroll
    const scrollDetectedAction = async () => {
      const scrollTop = mainRef.current?.scrollTop ?? 0;
      const scrollHeight = mainRef.current?.scrollHeight ?? 0;
      const offsetHeight = mainRef.current?.offsetHeight ?? 0;
      const edgeBuffer = 10; // this buffer vary based on the css config (border, padding, margin size)
      if (scrollTop > scrollHeight - offsetHeight - edgeBuffer) {
        if (isTopicLoading || !cursorInfo.hasNextPage) return;
        setIsLoadingMoreItems(true);
        await getTopicItems();
        setIsLoadingMoreItems(false);
      }
    };
    mainRef.current?.addEventListener('scroll', scrollDetectedAction);
    return () =>
      mainRef.current?.removeEventListener('scroll', scrollDetectedAction);
  }, [cursorInfo.hasNextPage, getTopicItems, isTopicLoading]);

  useEffect(() => {
    // NOTE: Initial load
    if (!isInitialLoaded.current) {
      getTopicItems(true);
    }
  }, [getTopicItems]);

  useEffect(() => {
    if (isrefetchTopicsData) {
      getTopicItems(true);
      setRefetchTopicsData(false);
    }
  }, [isrefetchTopicsData]);

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    switch (value) {
      case TABS.uiconfig.value:
        navigate('/alert/configs');
        localStorage.setItem(
          'hasSeenConfigUICardTooltip',
          JSON.stringify(true),
        );
        break;
      case TABS.storage.value:
        navigate('/alert/storage');
        break;
      default:
        navigate('/alert');
        break;
    }
  };

  const errorsParserList = useMemo(() => {
    if (!topicsData.length) return [];
    const uniqueTopics = new Map();
    topicsData.forEach((topic) => {
      const id = topic.fusionSource?.id;
      const isError =
        topic.fusionSource?.cursor?.sourceState === FusionSourceState.ERROR;
      if (id && isError && !uniqueTopics.has(id)) {
        uniqueTopics.set(id, topic);
      }
    });
    return Array.from(uniqueTopics.values());
  }, [topicsData]);

  if (topicsDataIsLoading || loadingSubscriptionConfigData)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '200px',
          width: '100%',
        }}
      >
        <CircularProgress size="50px" thickness={2} />
      </Box>
    );

  return (
    <Stack
      sx={{
        height: '100vh',
        overflowY: 'auto',
        padding: '5%',
        position: 'relative',
        scrollbarGutter: 'stable',
      }}
      ref={mainRef}
    >
      <Stack>
        <PageHeaderSection
          title="Alert Manager"
          subTitle="Welcome to the Notifi Network Admin Portal"
        />

        {topicsData && topicsData.length > 0 ? (
          <PrimaryButton
            buttonTitle="+ Topic"
            customSx={{
              height: '40px',
              padding: '10px',
              width: '100%',
              maxWidth: '90px',
              position: 'absolute',
              top: 'calc(5% + 80px)',
              right: '5%',
              zIndex: 10,
            }}
            onClick={() => {
              setIsCreateTopicModalOpen(true);
            }}
          />
        ) : null}
      </Stack>
      <Box
        sx={{
          borderBottom: '1px solid #B6B8D5',
          mt: 2,
          position: 'relative',
        }}
      >
        {!hasSeenConfigUICardTooltip &&
        topicsData.length > 0 &&
        cardConfigData.length === 0 ? (
          <Box ref={cnfigUICardRef}>
            <CalloutTooltip
              title="Create a card so your users can subscribe"
              subtitle="Your topic was created. Next, configure your UI so that users can subscribe in your app. "
              buttonText="Configure UI Card"
              isTooltipOpen={isShowTooltip}
              placement={'bottom-start'}
              onClick={async () => {
                localStorage.setItem(
                  'hasSeenConfigUICardTooltip',
                  JSON.stringify(true),
                );
                navigate('/alert/configs');
              }}
            >
              <Tabs value={0} onChange={handleChange}>
                <Tab
                  disableRipple
                  label="Topics"
                  className=""
                  sx={{
                    '&.MuiButtonBase-root': {
                      padding: 0,
                      minWidth: '40px',
                      '&.Mui-selected': {
                        color: midnight,
                      },
                    },
                  }}
                />
                <Tab
                  disableRipple
                  label="UI Config"
                  sx={{
                    '&.MuiButtonBase-root': {
                      padding: 0,
                      ml: 4,
                      minWidth: '40px',
                      '&.Mui-selected': {
                        color: midnight,
                      },
                    },
                  }}
                />
                {isMarketerRole ? null : (
                  <Tab
                    disableRipple
                    label="Storage"
                    sx={{
                      '&.MuiButtonBase-root': {
                        padding: 0,
                        ml: 4,
                        minWidth: '40px',
                        '&.Mui-selected': {
                          color: midnight,
                        },
                      },
                    }}
                  />
                )}
              </Tabs>
            </CalloutTooltip>
          </Box>
        ) : (
          <Tabs value={0} onChange={handleChange}>
            <Tab
              disableRipple
              label="Topics"
              className=""
              sx={{
                '&.MuiButtonBase-root': {
                  padding: 0,
                  minWidth: '40px',
                  '&.Mui-selected': {
                    color: midnight,
                  },
                },
              }}
            />
            <Tab
              disableRipple
              label="UI Config"
              sx={{
                '&.MuiButtonBase-root': {
                  padding: 0,
                  ml: 4,
                  minWidth: '40px',
                  '&.Mui-selected': {
                    color: midnight,
                  },
                },
              }}
            />
            {isMarketerRole ? null : (
              <Tab
                disableRipple
                label="Storage"
                sx={{
                  '&.MuiButtonBase-root': {
                    padding: 0,
                    ml: 4,
                    minWidth: '40px',
                    '&.Mui-selected': {
                      color: midnight,
                    },
                  },
                }}
              />
            )}
          </Tabs>
        )}
      </Box>
      <Stack spacing={2} mt="40px">
        <>
          <CreateTopicModal
            isCreateTopicModalOpen={isCreateTopicModalOpen}
            setIsCreateTopicModalOpen={setIsCreateTopicModalOpen}
          />

          {errorsParserList.length > 0 && (
            <Stack>
              <Typography
                sx={{
                  mb: '12px',
                  fontSize: '22px',
                  fontWeight: 900,
                  color: dusk,
                }}
              >
                Errors
              </Typography>
              <ErrorsSection
                topics={topicsData}
                errorsParserList={errorsParserList}
              />
            </Stack>
          )}

          <Stack>
            {errorsParserList.length > 0 && (
              <Typography
                sx={{
                  mb: '12px',
                  fontSize: '22px',
                  fontWeight: 900,
                  color: dusk,
                }}
              >
                Topics
              </Typography>
            )}
            <Stack>
              <TopicsTab
                isLoading={topicsDataIsLoading}
                topicsData={topicsData}
                cardConfigData={cardConfigData}
              />
            </Stack>
            {/* Load more */}
            {isLoadingMoreItems ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress size="50px" thickness={2} />
              </Box>
            ) : null}
            {error && (
              <Typography color="error" variant="body2">
                {error.message}
              </Typography>
            )}
          </Stack>
        </>
      </Stack>
    </Stack>
  );
};
