import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { dusk, light, midnight, rose } from 'src/constants/colors';
import {
  FusionEventCategory,
  useActivateFusionSourceMutation,
  WalletBlockchain,
} from 'src/services/gql/generated';
import { ReactComponent as IconError } from '../../assets/icn-error.svg';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { ReactComponent as IconClose } from '../../assets/icn-cancel.svg';
import { CustomFusionEventData } from './UIConfigTab';
import TestParserModal from '@components/alertManager/TestParserModal';
import LogsModal from '@components/alertManager/LogsModal';
import { useGetFusionTopicsData } from '@components/alertManager/TopicsDataContext';
import { chains } from '@components/cardConfig/form/CardConfigSelectChain';

type Props = {
  topics: CustomFusionEventData[];
  errorsParserList: CustomFusionEventData[];
};

const ListItemStyled = styled(ListItem)(() => ({
  backgroundColor: light,
  borderRadius: '8px',
  padding: '24px 40px',
  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
  marginBottom: '23px',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    background: 'linear-gradient(208deg, #FE7970 11.91%, #FEB776 88.99%)',
    width: '10px',
    borderRadius: '8px',
    height: '100%',
  },
}));

const ErrorsSection: React.FC<Props> = ({ topics, errorsParserList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cursorValue, setCursorValue] = useState('');
  const [fusionSourceId, setFusionSourceId] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTestParserModalOpen, setIsTestParserModalOpen] = useState(false);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);

  const { refetchFusionTopicsData } = useGetFusionTopicsData();

  const handleOpenModal = (id: string | undefined) => {
    setIsOpen(true);
    setFusionSourceId(id);
  };

  const handleOpenLogsModal = (id: string | undefined) => {
    setFusionSourceId(id);
    setIsLogsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (isLoading) return;
    setIsOpen(false);
    setCursorValue('');
    setFusionSourceId('');
  };

  const [activateFusionSource] = useActivateFusionSourceMutation();

  const showTopicType = (topic: any) => {
    const chain = chains.find(
      (chain) =>
        chain.name?.toLowerCase() ===
        topic?.fusionSource?.blockchainSource?.toLowerCase(),
    );
    if (topic.fusionEventCategory === FusionEventCategory.COMMUNITY_MANAGER) {
      return 'Community Manager';
    } else if (topic.fusionEventCategory === FusionEventCategory.GENERAL) {
      if (
        topic?.fusionSource?.blockchainSource === WalletBlockchain.OFF_CHAIN
      ) {
        if (topic?.fusionSource?.name.startsWith('__')) {
          return 'Sent from my server';
        } else {
          return 'Off-chain';
        }
      } else {
        return (
          <Stack flexDirection="row" alignItems="center" sx={{ gap: '10px' }}>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                marginBottom: '4px',
              }}
            >
              <img
                src={chain?.image}
                alt={chain?.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  // marginBottom: '4px',
                }}
              />
            </Box>
            <Typography
              sx={{
                color: dusk,
                fontSize: '16px',
                fontWeight: 700,
                // lineHeight: '22px',
              }}
            >
              {chain?.name}
            </Typography>
          </Stack>
        );
      }
    } else {
      return '';
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!fusionSourceId || !cursorValue) return;
    setIsLoading(true);

    try {
      await activateFusionSource({
        variables: {
          fusionSourceId,
          cursorValue,
        },
      });

      await new Promise((res) => {
        setTimeout(() => {
          refetchFusionTopicsData();
          res('');
        }, 5000);
      });

      setIsLoading(false);
      handleCloseModal();
    } catch (e) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorValue, fusionSourceId, activateFusionSource]);

  return (
    <>
      <List>
        {errorsParserList.map((parser) => {
          let followingTopics = [];
          if (parser?.fusionSource) {
            followingTopics = topics?.filter((item) => {
              return parser.fusionSource?.fusionEventTypeIds?.includes(item.id);
            });
          }

          return (
            <ListItemStyled disablePadding key={parser.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap="10px"
                sx={{
                  flex: 1,
                  '@media screen and (max-width: 1024px)': {
                    justifyContent: 'start',
                  },
                }}
              >
                <Stack sx={{ gap: '8px' }}>
                  <Stack direction="row">
                    <IconError />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 900,
                        color: rose,
                        ml: '8px',
                      }}
                    >
                      Parser Error in block
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 900,
                        color: rose,
                        ml: '8px',
                      }}
                    >
                      {parser.fusionSource?.cursor?.cursorValue ?? ''}
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap={'8px'}>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 900,
                        color: midnight,
                      }}
                    >
                      {parser?.fusionSource?.name}
                    </Typography>

                    {showTopicType(parser)}
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        color: dusk,
                      }}
                    >
                      | &nbsp;Used in
                      <Typography
                        component="span"
                        sx={{ color: dusk, marginLeft: '4px' }}
                      >
                        {followingTopics.length} topics
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  sx={{
                    gap: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    flexBasis: '50%',
                    position: 'relative',
                    justifyContent: 'end',
                    '@media screen and (max-width: 1024px)': {
                      justifyContent: 'start',
                      flexWrap: 'wrap',
                      flexBasis: '100%',
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    sx={{
                      textTransform: 'none',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '120px',
                      height: 'auto',
                      textWrap: 'nowrap',
                    }}
                    onClick={() => handleOpenLogsModal(parser.fusionSource?.id)}
                  >
                    Logs
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    sx={{
                      textTransform: 'none',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '120px',
                      height: 'auto',
                      textWrap: 'nowrap',
                    }}
                    onClick={() => setIsTestParserModalOpen(true)}
                  >
                    Test Parser on Block
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    sx={{
                      textTransform: 'none',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '120px',
                      height: 'auto',
                      textWrap: 'nowrap',
                    }}
                    onClick={() => handleOpenModal(parser.fusionSource?.id)}
                  >
                    Reactivate
                  </Button>
                </Stack>
              </Stack>
            </ListItemStyled>
          );
        })}
      </List>
      {isLogsModalOpen && (
        <LogsModal
          open={true}
          setIsOpen={setIsLogsModalOpen}
          fusionSourceId={fusionSourceId ?? ''}
        />
      )}
      <TestParserModal
        fusionSourceId={fusionSourceId ?? ''}
        open={isTestParserModalOpen}
        setIsOpen={setIsTestParserModalOpen}
      />
      <ConfirmDialog
        title="Set parser to active"
        open={isOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleSubmit}
        ctaTitle="Save"
        sx={{ width: '600px' }}
        disabled={isLoading}
      >
        <Box
          sx={{
            position: 'absolute',
            right: '50px',
            top: '30px',
            width: '15px',
            height: '15px',
            cursor: 'pointer',
          }}
          onClick={handleCloseModal}
        >
          <IconClose />
        </Box>

        {isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              zIndex: '100',
            }}
          >
            <CircularProgress size="30px" thickness={6} />
          </Box>
        ) : null}

        <Stack sx={{ alignItems: 'center', justifyContent: 'center', mb: 4 }}>
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            Must be within 1,000 blocks from current
          </Typography>
        </Stack>

        <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <TextField
            sx={{
              width: '360px',
            }}
            label={'Latest Block'}
            onChange={(e) => {
              const value = (e.target as HTMLInputElement).value;
              setCursorValue(value);
            }}
          />
        </Stack>
      </ConfirmDialog>
    </>
  );
};

export default ErrorsSection;
