import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  blueglass,
  cloud,
  dusk,
  eggshell,
  heather,
  midnight,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import { ReactComponent as IconUnion } from '../../../assets/icn-union.svg';
import ParserStatus from './ParserStatus';
import { ReactComponent as IconDev } from '../../../assets/icn-dev.svg';
import { ReactComponent as IconUpload } from '../../../assets/icn-upload.svg';
import { ReactComponent as IconTest } from '../../../assets/icn-test-parser.svg';
import { ReactComponent as IconEye } from '../../../assets/icn-eye.svg';
import { ReactComponent as IconRemove } from '../../../assets/icn-remove.svg';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import {
  FusionEventsDocument,
  FusionSourceWithCursor,
  useCreateFusionSourceUploadUrlMutation,
  useDeleteFusionSourceMutation,
  useFusionExecutionLogsQuery,
  useRemoveFusionEventFromFusionSourceMutation,
  WalletBlockchain,
  FusionExecutionLogDataType,
  FusionExecutionStatus,
} from 'src/services/gql/generated';
import { toEllipse } from 'src/util/stringUtils';
import { formatDate } from 'src/util/dateUtils';
import { useGetFusionTopicsData } from '@components/alertManager/TopicsDataContext';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ChangeParserModal from './ChangeParser';
import { fusionSourceFilterType as fusionSourceFilter } from '../GeneralEditTopicView';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import TestParserModal from '@components/alertManager/TestParserModal';
import LogsModal from '@components/alertManager/LogsModal';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import EditParserDialog from './EditParserDialog';
import { CopyIcon } from '@components/CopyIcon';
import { CheckIcon } from '@components/cardConfig/form/topicTypes/TopicIconSelector/assets/CheckIcon';
import { covertCronToFrequencyAndUnit } from 'src/hooks/useFrequencyByUnit';

type fusionSourceFilterType = fusionSourceFilter & {
  CronExecutionInterval: string;
};

type Props = {
  fusionSource: FusionSourceWithCursor;
  fusionEventId: string;
  onCreateParser: () => void;
};

type ParserStatusRef = {
  activateParser: () => Promise<void>;
};
const options = [
  {
    icon: <IconDev />,
    label: 'Continue Development',
    type: 'dev',
  },
  {
    icon: <IconTest />,
    label: 'Test Parser on Block',
    type: 'test',
  },
  {
    icon: <IconUpload />,
    label: 'Upload New Version',
    type: 'upload',
  },
  {
    icon: <IconEye />,
    label: 'View Logs',
    type: 'view',
  },
  {
    icon: <IconRemove />,
    label: 'Remove From Topic',
    type: 'remove',
  },
  {
    icon: <SwapHorizIcon sx={{ color: heather }} />,
    label: 'Change Parser',
    type: 'change',
  },
];
const isGetFusionSourceExecutionDetailsResult = (
  log: any,
): log is {
  cursorId: string;
  executionTimestamp: string;
  fusionSourceId: string;
  logData: string;
  logDataType: FusionExecutionLogDataType;
  status: FusionExecutionStatus;
} => {
  return (log as { cursorId: string }).cursorId !== undefined;
};

const ParserCard: React.FC<Props> = ({
  fusionSource,
  fusionEventId,
  onCreateParser,
}) => {
  const { items: topics } = useGetFusionTopicsData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTooltip, setOpenTooltip] = useState<{
    isVisible: boolean;
    data?: string;
    anchorEl?: HTMLElement;
  }>({ isVisible: false });
  const [isViewAllTopics, setIsViewAllTopics] = useState(false);
  const [isEditNameOpen, setIsEditNameOpen] = useState(false);
  const [isRemoveTopicOpen, setIsRemoveTopicOpen] = useState(false);
  const [isRemoveParserOpen, setIsRemoveParserOpen] = useState(false);
  const [isOpenTestParserOpen, setIsOpenTestParserOpen] = useState(false);
  const [isChangeParserOpen, setIsChangeParserOpen] = useState(false);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [isExpand, setisExpand] = useState(false);
  const parserStatusRef = useRef<ParserStatusRef>(null);
  const [removeImpl] = useRemoveFusionEventFromFusionSourceMutation();
  const [deleteImpl] = useDeleteFusionSourceMutation();
  const [createUploadUrl] = useCreateFusionSourceUploadUrlMutation();

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const fusionSourceFilter: fusionSourceFilterType = JSON.parse(
    fusionSource?.filter || '{}',
  );

  const open = Boolean(anchorEl);
  const cron = covertCronToFrequencyAndUnit(
    fusionSourceFilter?.CronExecutionInterval ?? '',
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    clearMessageBarState();
  };

  const handleClose = () => {
    setAnchorEl(null);
    clearMessageBarState();
  };

  const handleTopicHashClick = (item: string, anchorEl: HTMLElement) => {
    setOpenTooltip({ isVisible: true, data: item, anchorEl });
  };

  const handleEditName = () => {
    setIsEditNameOpen(true);
  };

  const handleCloseEditName = () => {
    setIsEditNameOpen(false);
  };

  const handleRemoveTopic = () => {
    setIsRemoveTopicOpen(true);
  };

  const handleConfirmRemoveTopic = useCallback(async () => {
    await removeImpl({
      variables: {
        fusionEventId,
      },
      refetchQueries: [
        {
          query: FusionEventsDocument,
        },
      ],
    });
    setIsRemoveTopicOpen(false);
  }, [fusionEventId, removeImpl]);

  const handleDeleteParser = () => {
    setIsRemoveParserOpen(true);
  };

  const handleClickAway = useCallback(() => {
    setOpenTooltip({ isVisible: false });
    setTextCopied(false);
  }, []);

  const handleConfirmRemoveParser = useCallback(async () => {
    if (!fusionSource) return;
    await deleteImpl({
      variables: {
        fusionSourceId: fusionSource.id,
      },
      refetchQueries: [
        {
          query: FusionEventsDocument,
        },
      ],
    });
    setIsRemoveParserOpen(false);
  }, [deleteImpl, fusionSource]);

  const handleUploadParser = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const formData = new FormData();
      let url = '';
      if (!e.target.files) return;
      const file = e.target.files[0];
      formData.append('js-file', file);
      try {
        const byteCount = file.size;
        const res = await createUploadUrl({
          variables: {
            fusionSourceId: fusionSource.id,
            byteCount,
          },
          refetchQueries: [
            {
              query: FusionEventsDocument,
            },
          ],
        });
        url =
          res?.data?.createFusionSourceUploadUrl
            ?.createFusionSourceUploadUrlResponse?.fusionSourcePutUrl ?? '';
        if (url === '') {
          return Promise.reject('Failed to upload');
        }
      } catch (e) {
        setErrorMessage((e as Error).message);
        return;
      }
      fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'content-type': file.type,
          'content-length': `${file.size}`,
        },
      }).then((res) => res.json());
    },
    [createUploadUrl, fusionSource.id],
  );

  const handleClick = (type: string) => {
    switch (type) {
      case 'test':
        setIsOpenTestParserOpen(true);
        break;
      case 'remove':
        handleRemoveTopic();
        break;
      case 'delete':
        handleDeleteParser();
        break;
      case 'view':
        setIsLogsModalOpen(true);
        break;
      case 'change':
        setIsChangeParserOpen(true);
        break;
      case 'dev':
        onCreateParser();
        break;
    }
  };

  const followingTopics = useMemo(() => {
    if (!fusionSource) return;
    return topics.filter((item) =>
      fusionSource.fusionEventTypeIds?.includes(item.id),
    );
  }, [fusionSource, topics]);

  const fileInput = document.getElementById('fileInput');

  // Trigger click event to open file selector dialog
  fileInput?.click();

  function isInvalidDate(dateString: string | null | undefined) {
    return (
      dateString === null ||
      dateString === undefined ||
      dateString === '0001-01-01T00:00:00.000Z'
    );
  }

  const onTextCopied = (event: Event | SyntheticEvent<Element, Event>) => {
    event.stopPropagation();
    setTextCopied(true);
    setTimeout(() => setTextCopied(false), 2000);
  };

  const { data } = useFusionExecutionLogsQuery({
    variables: {
      getFusionExecutionLogsInput: { fusionSourceOrModuleId: fusionSource.id },
    },
  });

  const handleReActivateParser = () => {
    if (parserStatusRef.current) {
      parserStatusRef.current.activateParser();
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: '#FEFEFE',
          padding: '24px 30px 24px 30px',
          borderRadius: '8px',
          boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            mb: '12px',
          }}
        >
          <Stack>
            <Typography sx={{ fontSize: '22px', fontWeight: 900 }}>
              Parser
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 800, color: dusk }}>
              {isInvalidDate(fusionSource.cursor?.lastExecutedTimestamp) ? (
                'This parser has not been executed yet'
              ) : (
                <>
                  Last executed{' '}
                  {formatDate(
                    fusionSource.cursor?.lastExecutedTimestamp,
                    'dd/MM/yyyy HH:mm aa',
                  )}
                </>
              )}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              gap: 2,
            }}
          >
            <Button
              sx={{
                minWidth: '32px',
                height: '32px',
                borderRadius: '50%',
                '&:active': {
                  backgroundColor: cloud,
                },
              }}
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpen}
            >
              <IconUnion />
            </Button>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{
                vertical: 'top',
                horizontal: 210,
              }}
              PaperProps={{
                style: {
                  width: '240px',
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => handleClick(option.type)}
                >
                  <Stack
                    direction="row"
                    sx={{ alignItems: 'center', gap: '8px' }}
                  >
                    {option.icon}
                    <label>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color: midnight,
                          fontWeight: 700,
                        }}
                      >
                        {option.label}
                      </Typography>
                      {option.type === 'upload' ? (
                        <>
                          <TextField
                            type="file"
                            sx={{ display: 'none' }}
                            onChange={handleUploadParser}
                            inputProps={{ accept: '.js' }}
                          />
                        </>
                      ) : null}
                    </label>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>
            <PrimaryButton
              buttonTitle="Edit"
              customSx={{ height: '28px', fontSize: '14px' }}
              onClick={handleEditName}
            />
          </Stack>
        </Stack>

        <Box sx={{ borderRadius: '8px', background: eggshell, p: '16px' }}>
          <Stack direction="row" mb="12px">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 800,
                color: dusk,
                width: '80px',
              }}
            >
              Blockchain
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: midnight,
                flex: 1,
              }}
            >
              {fusionSource?.blockchainSource?.toLowerCase()}
            </Typography>
          </Stack>
          <Stack direction="row" mb="12px">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 800,
                color: dusk,
                width: '80px',
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: midnight,
                flex: 1,
              }}
            >
              {fusionSource.name}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="start" mb="12px">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 800,
                color: dusk,
                width: '80px',
                // paddingTop: '10px',
              }}
            >
              Status
            </Typography>
            <Stack
              direction="column"
              justifyContent={'flex-start'}
              sx={{ flex: 1 }}
            >
              <ParserStatus
                ref={parserStatusRef}
                fusionSource={fusionSource}
                isExpand={isExpand}
                setisExpand={setisExpand}
              />
            </Stack>
          </Stack>

          {isExpand && (
            <Stack
              direction={'column'}
              marginBottom={'12px'}
              sx={{
                backgroundColor: white,
                padding: '10px 8px 0 0',
              }}
            >
              <List
                sx={{
                  maxHeight: '150px',
                  height: '100%',
                  overflowY: 'scroll',

                  padding: 0,
                  borderRadius: '4px',
                }}
              >
                {data?.fusionExecutionLogs?.nodes?.map((log) =>
                  isGetFusionSourceExecutionDetailsResult(log) ? (
                    <ListItem key={log.cursorId}>
                      <ListItemText
                        primary={`Cursor ID: ${log.cursorId}`}
                        secondary={
                          <>
                            <Typography component="span">
                              Execution Timestamp:
                              {new Date(
                                log.executionTimestamp,
                              ).toLocaleString()}
                            </Typography>
                            <br />
                            <Typography component="span">
                              Status: {log.status}
                            </Typography>
                            <br />
                            <Typography component="span">
                              Log Data:{' '}
                              <a
                                href={log.logData}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: blueglass,
                                  fontWeight: 800,
                                  textDecoration: 'none',
                                }}
                              >
                                View Log
                              </a>
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ) : null,
                )}
              </List>

              <PrimaryButton
                buttonTitle="Reactivate"
                customSx={{
                  height: '28px',
                  fontSize: '14px',
                  width: 'calc(100% + 10px)',
                }}
                onClick={handleReActivateParser}
              />
            </Stack>
          )}

          <Stack direction="row" mb="12px">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 800,
                color: dusk,
                width: '80px',
              }}
            >
              {fusionSourceFilter?.CronExecutionInterval ? 'Cron' : 'Topic #s'}
            </Typography>
            <Stack>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grid
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: 'repeat(1,minmax(0,1fr))',
                      md: 'repeat(2,minmax(0,1fr))',
                    },
                    gap: '6px 16px',
                    maxHeight: isViewAllTopics ? 'auto' : '50px',
                    overflowY: 'hidden',
                  }}
                >
                  {fusionSourceFilter?.CronExecutionInterval ? (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: midnight,
                        width: '100%',
                      }}
                    >
                      Every {cron.frequency} {cron.unit?.toLowerCase()}
                    </Typography>
                  ) : null}

                  {fusionSourceFilter.TopicHashes?.map((item) => (
                    <Stack
                      key={item}
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: midnight,
                        width: '90px',
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={(event) =>
                        handleTopicHashClick(
                          item,
                          event.currentTarget as HTMLElement,
                        )
                      }
                    >
                      {toEllipse(item, 5, 5)}
                      <Popper
                        anchorEl={openTooltip.anchorEl}
                        open={openTooltip.isVisible}
                        placement="top"
                        sx={{ zIndex: 2000 }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent={textCopied ? 'center' : 'start'}
                          sx={{
                            p: '16px',
                            borderRadius: '8px',
                            backgroundColor: white,
                            marginBottom: '8px',
                            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                            pl: textCopied ? '10px' : '20px',
                            border: `1px solid ${cloud}`,
                          }}
                          onClick={(e) => onTextCopied(e)}
                        >
                          {textCopied ? <CheckIcon color={dusk} /> : null}
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              whiteSpace: 'pre-line',
                              color: textCopied ? dusk : blueglass,
                              cursor: 'pointer',
                            }}
                          >
                            {textCopied
                              ? 'Copied to clipboard'
                              : openTooltip.data
                              ? openTooltip.data
                              : null}
                          </Typography>
                          {!textCopied && (
                            <Button
                              sx={{
                                p: 0,
                                m: 0,
                                minWidth: '0px',
                                height: '15px',
                                marginLeft: '10px',
                              }}
                            >
                              <CopyIcon copyText={item} color={blueglass} />
                            </Button>
                          )}
                        </Stack>
                      </Popper>
                    </Stack>
                  ))}
                </Grid>
              </ClickAwayListener>
              {fusionSourceFilter.TopicHashes &&
              fusionSourceFilter.TopicHashes?.length > 4 ? (
                <Typography
                  onClick={() => setIsViewAllTopics(!isViewAllTopics)}
                  sx={{
                    fontSize: '14px',
                    fontWeight: 800,
                    color: blueglass,
                    mt: '8px',
                    cursor: 'pointer',
                  }}
                >
                  View All
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Box>
        {/* TODO: clean up the logic here */}
        {fusionSource.cursor &&
        (fusionSource.cursor.sourceState === 'ERROR' ||
          fusionSource.cursor.sourceState === 'INACTIVE') ? (
          fusionSource?.blockchainSource ===
          WalletBlockchain.OFF_CHAIN ? null : (
            <SecondaryButton
              onClick={() => setIsOpenTestParserOpen(true)}
              buttonTitle="Test Parser on Block"
              customSx={{
                borderColor: seaglassDarker,
                color: seaglassDarker,
                mt: 2,
              }}
            />
          )
        ) : null}
      </Box>
      {isLogsModalOpen && (
        <LogsModal
          open={true}
          setIsOpen={setIsLogsModalOpen}
          fusionSourceId={fusionSource.id ?? ''}
        />
      )}
      <TestParserModal
        open={isOpenTestParserOpen}
        setIsOpen={setIsOpenTestParserOpen}
        fusionSourceId={fusionSource.id}
      />
      <ChangeParserModal
        open={isChangeParserOpen}
        handleClose={() => setIsChangeParserOpen(false)}
      />
      <EditParserDialog
        open={isEditNameOpen}
        handleClose={handleCloseEditName}
        fusionSource={fusionSource}
      />

      <ConfirmDialog
        title="Confirm that you would like to delete this topic"
        open={isRemoveTopicOpen}
        handleClose={() => setIsRemoveTopicOpen(false)}
        handleConfirm={handleConfirmRemoveTopic}
        ctaTitle="Delete Topic"
      />

      <ConfirmDialog
        title="Confirm that you would like to delete this parser"
        open={isRemoveParserOpen}
        handleClose={() => setIsRemoveParserOpen(false)}
        handleConfirm={handleConfirmRemoveParser}
        ctaTitle="Delete Parser"
        sx={{ width: '600px' }}
      >
        <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            sx={{
              color: dusk,
              fontSize: '16px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            This parser is used for the following topics:
          </Typography>
          <Stack sx={{ width: '100%', my: '24px' }}>
            <Stack sx={{ maxWidth: '250px', mx: 'auto', gap: '4px' }}>
              {followingTopics?.map((item) => (
                <Typography
                  key={item.id}
                  sx={{
                    color: midnight,
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                >
                  - {item.name}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </ConfirmDialog>
    </Fragment>
  );
};

export default ParserCard;
